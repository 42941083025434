export const getParams = function (name) {
	const params = new URLSearchParams(window.location.search);
	return "" + params.get(name);
};

export const isHostParam = () => getParams("isHost") === "true";

export const getGameSessionId = function () {
	return `${getParams("roomId")}+${getParams("sessionId")}`;
};

export const getAngle = count =>
	new Array(count).fill(0).map((item, index) => [index * (360 / count), (index + 1) * (360 / count)]);
