<script>
	import { getParams, getAngle } from "./utils";
	import {
		dbUsers,
		dbNoOfOnlineUsers,
		dbHost,
		dbGameSessionIsSpinning,
		dbGameSessionBottleAngle,
		dbGameSessionRoundValue,
		dbGameSessionRounds,
		dbGameSessionRound,
		dbChanceDisconnect,
		user,
	} from "./database";
	import GameThemes from "./GameThemes/index.svelte";
	import { amIHost } from "./hostStore"
	import { usersStore, disconnectModalStore } from "./store";
	import { DisconnectModal } from "da-components/v0";

	let userHost = "";
	let conic;
	let anglesArray;
	let headingAngles;
	const colors = [
		"#EFA33A",
		"#F7D961",
		"#78C073",
		"#A8DB5A",
		"#7BA8F8",
		"#96D6F3",
		"#D14854",
		"#EC8256",
		"#7BA8F8",
		"#96D6F3",
		"#D14854",
		"#EC8256",
		"#EFA33A",
		"#F7D961",
		"#78C073",
		"#A8DB5A",
	];
	let userNameHost;
	let hostDisconnect = false;
	let chanceDisconnect = false;
	let disconnectUser;
	let users = [];
	let noOfOnlineUsers = 0;
	let onlineUsersArray = [];
	let usersArray = [];
	let usersOnlineStatus = {};

	$: {
		if ($amIHost && users.length !== 0) {
			dbHost
				.get()
				.then(snap => {
					if (!snap.val()) {
						dbHost.set(user.id);
					}
				})
				.catch(err => console.log("error: ", err));
		}
	}

	$: dbChanceDisconnect.on("value", snap => {
		if (snap.exists()) {
			chanceDisconnect = true;
			let disconnectUserId = snap.val();
			let index = usersArray.findIndex(user => user.id === disconnectUserId);
			disconnectUser = usersArray[index].userName;
		} else {
			chanceDisconnect = false;
		}
	});
	$: if (userHost !== "") {
		let index = usersArray.findIndex(user => user.id === userHost);
		userNameHost = usersArray[index];
	}

	dbUsers.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		users = snap.val();
		usersArray = Object.values(users);
		usersStore.set(usersArray);
	});

	$: {
		if (users) {
			onlineUsersArray = [];
			for (const id in users) {
				let currUser = users[id];
				if (currUser.isOnline) {
					onlineUsersArray.push(currUser);
				}
				if (users[id].isOnline === false) {
					if (id === getParams("userId")) {
						// info('You have been disconnected, please check your internet connection','Disconnected',5000);
					} else {
						// info(`${users[id]['userName']} is disconnected. Waiting for ${users[id]['userName']} to connect again`,'Disconnected',5000);
					}
					usersOnlineStatus[id] = false;
				} else {
					if (id in usersOnlineStatus && usersOnlineStatus[id] === false) {
						if (id === getParams("userId")) {
							// info('You are reconnected','Reconnected',5000);
						} else {
							// info(`${users[id]['userName']} is reconnected`,'Reconnected',5000);
						}
					}
					usersOnlineStatus[id] = true;
				}
			}
		}
	}

	let bottleAngle = 45;
	let isSpinning = false;
	let roundValue = 1;
	let roundData;

	dbNoOfOnlineUsers.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		noOfOnlineUsers = snap.val();
		anglesArray = getAngle(noOfOnlineUsers);
		conic = anglesArray.map((item, index) => `${colors[index]} ${item[0]}deg ${item[1]}deg`).join(",");
		headingAngles = anglesArray.map(deg => (deg[0] + deg[1]) / 2 - 90);
	});

	dbGameSessionBottleAngle.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		bottleAngle = snap.val();
	});

	dbGameSessionIsSpinning.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		isSpinning = snap.val();
	});

	dbGameSessionRoundValue.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		if (roundValue !== snap.val()) {
			roundValue = snap.val();
			roundData = null;
		}
	});

	dbGameSessionRounds.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		const rounds = snap.val();
		roundData = rounds[roundValue];
	});

	const spin = () => {
		if (noOfOnlineUsers < 2) {
			return;
		}

		isSpinning = true;
		dbGameSessionIsSpinning.set(true);
	};

	const handleTruth = () => {
		roundData = {
			...roundData,
			selected: "Truth",
			page: "showTruthModal",
		};
		dbGameSessionRound().update({
			selected: "Truth",
			page: "showTruthModal",
		});
	};

	const handleDare = () => {
		roundData = {
			...roundData,
			selected: "Dare",
			page: "showDareModal",
		};
		dbGameSessionRound().update({
			selected: "Dare",
			page: "showDareModal",
		});
	};

	function handleTruthAudience() {
		roundData = {
			...roundData,
			type: "audience",
			page: "showTruthAudienceModal",
		};
		dbGameSessionRound().update({
			type: "audience",
			page: "showTruthAudienceModal",
		});
	}

	function handleTruthBank() {
		roundData = {
			...roundData,
			type: "bank",
			page: "showTruthBankModal",
		};
		dbGameSessionRound().update({
			type: "bank",
			page: "showTruthBankModal",
		});
	}

	function handleDareAudience() {
		roundData = {
			...roundData,
			type: "audience",
			page: "showDareAudienceModal",
		};
		dbGameSessionRound().update({
			type: "audience",
			page: "showDareAudienceModal",
		});
	}

	function handleDareBank() {
		roundData = {
			...roundData,
			type: "bank",
			page: "showDareBankModal",
		};
		dbGameSessionRound().update({
			type: "bank",
			page: "showDareBankModal",
		});
	}

	function handleForfeit() {
		roundData = {
			...roundData,
			isCompleted: false,
			page: "",
		};
		dbGameSessionRound().update({
			isCompleted: false,
			page: "",
		});
	}

	function handleComplete() {
		roundData = {
			...roundData,
			isCompleted: true,
			page: "",
		};
		dbGameSessionRound().update({
			isCompleted: true,
			page: "",
		});
	}
</script>

<h1 class="gameHeading">Truth or Dare</h1>
{#if noOfOnlineUsers < 2}
	<div class="circleProp" style={"background-image: conic-gradient(#EFA33A 1deg 180deg,#F7D961 181deg 360deg);"}>
		<img id="bottle" src="/bottle.svg" alt="bottle" style="transform: rotate(45deg);" />
		<h2 style="transform: rotate(0deg);">
			{onlineUsersArray?.[0]?.userName || "?"}
		</h2>
		<h2 style="transform: rotate(180deg);">
			{onlineUsersArray?.[1]?.userName || "?"}
		</h2>
	</div>
{:else}
	<div class="circleProp" style="background-image: conic-gradient({conic});">
		<img id="bottle" src="/bottle.svg" alt="bottle" style="transform: rotate({bottleAngle - 45}deg);" />
		{#each onlineUsersArray as user, i}
			<h2 style="transform: rotate({headingAngles[i]}deg);">{user.userName}</h2>
		{/each}
	</div>
{/if}

{#if isSpinning && !roundData}
	<h3 class="waiting">Starting Spin....</h3>
{:else if isSpinning && roundData}
	<h3 class="waiting">Spinning...</h3>
{:else if roundData}
	<h3 class="waiting">
		{users[roundData.userId].userName}'s chance in progress...
	</h3>
{:else if $amIHost}
	<button class="ready" on:click={spin}>Spin</button>
{:else}
	<h3 class="waiting">Waiting for host to spin...</h3>
{/if}

{#if !isSpinning && getParams("userId") === roundData?.userId}
	{#if roundData?.page === "showChoiceModal"}
		<div class="modal">
			<div class="choice">
				<h1>Your turn</h1>
				<p>Pick "Truth" or "Dare" to proceed</p>
				<div class="choiceButtonDiv">
					<button on:click={handleTruth} class="truth">Truth</button>
					<button on:click={handleDare} class="dare">Dare</button>
				</div>
			</div>
		</div>
	{:else if roundData?.page === "showTruthModal"}
		<div class="modal">
			<div class="choice">
				<h1>Your turn</h1>
				<p>Pick your choice to proceed</p>
				<div class="choiceButtonDiv">
					<button on:click={handleTruthAudience} class="audience">Audience</button>
					<button on:click={handleTruthBank} class="bank">Bank</button>
				</div>
			</div>
		</div>
	{:else if roundData?.page === "showTruthAudienceModal"}
		<div class="modal">
			<div class="choice">
				<h3 class="truthHeading" style="margin-bottom: 6px;">TRUTH</h3>
				<h1 style="font-size: 16px; margin-bottom: 25px;">People should ask.</h1>
				<div class="choiceButtonDiv" id="marginBottom">
					<button on:click={handleForfeit} class="forfeit">Forfeit</button>
					<button on:click={handleComplete} class="complete">Complete</button>
				</div>
			</div>
		</div>
	{:else if roundData?.page === "showTruthBankModal"}
		<div class="modal">
			<div id="altModal" class="choice">
				<h3 id="altHeading" class="truthHeading">TRUTH</h3>
				<h5>{roundData?.question || "Picking a random truth from question bank..."}</h5>
				{#if roundData?.question}
					<div id="buttonDiv" class="choiceButtonDiv">
						<button on:click={handleForfeit} class="forfeit">Forfeit</button>
						<button on:click={handleComplete} class="complete">Complete</button>
					</div>
				{/if}
			</div>
		</div>
	{:else if roundData?.page === "showDareModal"}
		<div class="modal">
			<div class="choice">
				<h1>Your turn</h1>
				<p>Pick your choice to proceed</p>
				<div class="choiceButtonDiv">
					<button on:click={handleDareAudience} class="audience">Audience</button>
					<button on:click={handleDareBank} class="bank">Bank</button>
				</div>
			</div>
		</div>
	{:else if roundData?.page === "showDareAudienceModal"}
		<div class="modal">
			<div class="choice">
				<h3 class="dareHeading" style="margin-bottom: 6px;">DARE</h3>
				<h1 style="font-size: 20px; margin-bottom: 25px;">People should ask.</h1>
				<div class="choiceButtonDiv" id="marginBottom">
					<button on:click={handleForfeit} class="forfeit">Forfeit</button>
					<button on:click={handleComplete} class="complete">Complete</button>
				</div>
			</div>
		</div>
	{:else if roundData?.page === "showDareBankModal"}
		<div class="modal">
			<div id="altModal" class="choice">
				<h3 id="altHeading" class="dareHeading">DARE</h3>
				<h5>{roundData?.question || "Picking a random dare from question bank..."}</h5>
				{#if roundData?.question}
					<div id="buttonDiv" class="choiceButtonDiv">
						<button on:click={handleForfeit} class="forfeit">Forfeit</button>
						<button on:click={handleComplete} class="complete">Complete</button>
					</div>
				{/if}
			</div>
		</div>
	{/if}
{:else if !isSpinning && roundData}
	{#if roundData?.page === "showChoiceModal" || roundData?.page === "showTruthModal" || roundData?.page === "showDareModal"}
		<div class="modal">
			<div class="choice">
				<h1 class="marginBottom">{users[roundData.userId].userName}'s turn</h1>
				<p>
					{users[roundData.userId].userName.charAt(0).toUpperCase() +
						users[roundData.userId].userName.slice(1)} has to pick "Truth" or "Dare" to proceed
				</p>
			</div>
		</div>
	{:else if roundData?.page === "showTruthAudienceModal"}
		<div class="modal">
			<div class="choice">
				<h3 class="truthHeading">TRUTH</h3>
				<svg
					class="yellowSvg"
					width="42"
					height="36"
					viewBox="0 0 42 36"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M29.583 0.749969C27.4651 0.749969 25.7497 2.46539 25.7497 4.5833V16.832C25.7497 17.8689 27.0053 18.3897 27.7375 17.6556L30.9906 14.1666H37.2497C38.0432 14.1666 38.7795 13.9255 39.3909 13.5115C39.7992 13.2355 40.1519 12.8828 40.4279 12.4746C40.8419 11.8632 41.083 11.1268 41.083 10.3333V4.5833C41.083 3.7898 40.8419 3.05344 40.4279 2.44203C40.1519 2.03378 39.7992 1.68108 39.3909 1.40508C38.7795 0.99108 38.0432 0.749969 37.2497 0.749969H29.583ZM15.208 4.5833C13.4288 4.5833 11.7226 5.29007 10.4645 6.54813C9.20644 7.80618 8.49967 9.51248 8.49967 11.2916C8.49967 13.0708 9.20644 14.7771 10.4645 16.0351C11.7226 17.2932 13.4288 18 15.208 18C16.9872 18 18.6935 17.2932 19.9515 16.0351C21.2096 14.7771 21.9163 13.0708 21.9163 11.2916C21.9163 9.51248 21.2096 7.80618 19.9515 6.54813C18.6935 5.29007 16.9872 4.5833 15.208 4.5833ZM15.208 21.8333C9.48676 21.8333 0.833008 25.2737 0.833008 30.4583V33.3333C0.833008 34.3913 1.69167 35.25 2.74967 35.25H27.6663C28.7243 35.25 29.583 34.3913 29.583 33.3333V30.4583C29.583 25.2737 20.9293 21.8333 15.208 21.8333Z"
						fill="url(#paint0_linear)"
					/>
					<defs>
						<linearGradient
							id="paint0_linear"
							x1="20.958"
							y1="0.749969"
							x2="20.958"
							y2="35.25"
							gradientUnits="userSpaceOnUse"
						>
							<stop stop-color="#FDBC06" />
							<stop offset="1" stop-color="#FD990A" />
						</linearGradient>
					</defs>
				</svg>
				<h2 class="peopleTruth" style="padding-bottom: 10px;">
					People should ask {users[roundData.userId].userName}
				</h2>
			</div>
		</div>
	{:else if roundData?.page === "showTruthBankModal"}
		<div class="modal">
			<div id="altModal" class="choice">
				<h3 id="altHeading" class="truthHeading">TRUTH</h3>
				<h5 id="question">{roundData?.question || "Picking a random truth from question bank..."}</h5>
			</div>
		</div>
	{:else if roundData?.page === "showDareAudienceModal"}
		<div class="modal">
			<div class="choice">
				<h3 class="dareHeading">DARE</h3>
				<svg
					class="yellowSvg"
					width="42"
					height="36"
					viewBox="0 0 42 36"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M29.583 0.749969C27.4651 0.749969 25.7497 2.46539 25.7497 4.5833V16.832C25.7497 17.8689 27.0053 18.3897 27.7375 17.6556L30.9906 14.1666H37.2497C38.0432 14.1666 38.7795 13.9255 39.3909 13.5115C39.7992 13.2355 40.1519 12.8828 40.4279 12.4746C40.8419 11.8632 41.083 11.1268 41.083 10.3333V4.5833C41.083 3.7898 40.8419 3.05344 40.4279 2.44203C40.1519 2.03378 39.7992 1.68108 39.3909 1.40508C38.7795 0.99108 38.0432 0.749969 37.2497 0.749969H29.583ZM15.208 4.5833C13.4288 4.5833 11.7226 5.29007 10.4645 6.54813C9.20644 7.80618 8.49967 9.51248 8.49967 11.2916C8.49967 13.0708 9.20644 14.7771 10.4645 16.0351C11.7226 17.2932 13.4288 18 15.208 18C16.9872 18 18.6935 17.2932 19.9515 16.0351C21.2096 14.7771 21.9163 13.0708 21.9163 11.2916C21.9163 9.51248 21.2096 7.80618 19.9515 6.54813C18.6935 5.29007 16.9872 4.5833 15.208 4.5833ZM15.208 21.8333C9.48676 21.8333 0.833008 25.2737 0.833008 30.4583V33.3333C0.833008 34.3913 1.69167 35.25 2.74967 35.25H27.6663C28.7243 35.25 29.583 34.3913 29.583 33.3333V30.4583C29.583 25.2737 20.9293 21.8333 15.208 21.8333Z"
						fill="url(#paint0_linear)"
					/>
					<defs>
						<linearGradient
							id="paint0_linear"
							x1="20.958"
							y1="0.749969"
							x2="20.958"
							y2="35.25"
							gradientUnits="userSpaceOnUse"
						>
							<stop stop-color="#FDBC06" />
							<stop offset="1" stop-color="#FD990A" />
						</linearGradient>
					</defs>
				</svg>
				<h2 class="peopleDare" style="padding-bottom: 10px;">
					People should ask {users[roundData.userId].userName}
				</h2>
			</div>
		</div>
	{:else if roundData?.page === "showDareBankModal"}
		<div class="modal">
			<div id="altModal" class="choice">
				<h3 id="altHeading" class="dareHeading">DARE</h3>
				<h5 id="question">{roundData?.question || "Picking a random dare from question bank..."}</h5>
			</div>
		</div>
	{/if}
{/if}

{#if hostDisconnect}
	<div class="disconnectDiv">
		<div class="dissconectDivImg">
			<div class="gradient" />
			<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M12.19 0.691071C11.542 0.690966 10.8945 0.926578 10.4224 1.39908L8.58322 3.23827L7.92241 2.57746L6.74403 3.75585L8.23817 5.24999L6.74403 6.74413L7.92241 7.92252L9.41655 6.42838L11.5715 8.58332L10.0774 10.0775L11.2557 11.2558L12.7499 9.76171L14.244 11.2558L15.4224 10.0775L14.7616 9.41666L16.6008 7.57746C17.5758 6.60246 17.5758 5.01731 16.6008 4.04231L15.8684 3.30989L17.0891 2.08918L15.9107 0.910797L14.69 2.1315L13.9576 1.39908C13.4859 0.926995 12.838 0.691175 12.19 0.691071ZM3.75575 6.74413L2.57736 7.92252L3.23817 8.58332L1.39897 10.4225C0.423975 11.3975 0.423975 12.9827 1.39897 13.9577L2.1314 14.6901L0.910693 15.9108L2.08908 17.0892L3.30978 15.8685L4.0422 16.6009C4.51387 17.0726 5.14145 17.3333 5.80978 17.3333C6.47728 17.3333 7.10569 17.0734 7.57736 16.6009L9.41655 14.7617L10.0774 15.4225L11.2557 14.2441L3.75575 6.74413Z"
					fill="white"
				/>
			</svg>
		</div>
		<h2>{userNameHost !== undefined ? userNameHost.userName : ""} is the new host for the game.</h2>
	</div>
{/if}

{#if noOfOnlineUsers < 2}
	<div class="disconnectDiv">
		<div class="dissconectDivImg">
			<div class="gradient" />
			<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M12.19 0.691071C11.542 0.690966 10.8945 0.926578 10.4224 1.39908L8.58322 3.23827L7.92241 2.57746L6.74403 3.75585L8.23817 5.24999L6.74403 6.74413L7.92241 7.92252L9.41655 6.42838L11.5715 8.58332L10.0774 10.0775L11.2557 11.2558L12.7499 9.76171L14.244 11.2558L15.4224 10.0775L14.7616 9.41666L16.6008 7.57746C17.5758 6.60246 17.5758 5.01731 16.6008 4.04231L15.8684 3.30989L17.0891 2.08918L15.9107 0.910797L14.69 2.1315L13.9576 1.39908C13.4859 0.926995 12.838 0.691175 12.19 0.691071ZM3.75575 6.74413L2.57736 7.92252L3.23817 8.58332L1.39897 10.4225C0.423975 11.3975 0.423975 12.9827 1.39897 13.9577L2.1314 14.6901L0.910693 15.9108L2.08908 17.0892L3.30978 15.8685L4.0422 16.6009C4.51387 17.0726 5.14145 17.3333 5.80978 17.3333C6.47728 17.3333 7.10569 17.0734 7.57736 16.6009L9.41655 14.7617L10.0774 15.4225L11.2557 14.2441L3.75575 6.74413Z"
					fill="white"
				/>
			</svg>
		</div>
		<h2>There are less then two people connected!</h2>
	</div>
{/if}
{#if chanceDisconnect}
	<div class="disconnectDiv">
		<div class="dissconectDivImg">
			<div class="gradient" />
			<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M12.19 0.691071C11.542 0.690966 10.8945 0.926578 10.4224 1.39908L8.58322 3.23827L7.92241 2.57746L6.74403 3.75585L8.23817 5.24999L6.74403 6.74413L7.92241 7.92252L9.41655 6.42838L11.5715 8.58332L10.0774 10.0775L11.2557 11.2558L12.7499 9.76171L14.244 11.2558L15.4224 10.0775L14.7616 9.41666L16.6008 7.57746C17.5758 6.60246 17.5758 5.01731 16.6008 4.04231L15.8684 3.30989L17.0891 2.08918L15.9107 0.910797L14.69 2.1315L13.9576 1.39908C13.4859 0.926995 12.838 0.691175 12.19 0.691071ZM3.75575 6.74413L2.57736 7.92252L3.23817 8.58332L1.39897 10.4225C0.423975 11.3975 0.423975 12.9827 1.39897 13.9577L2.1314 14.6901L0.910693 15.9108L2.08908 17.0892L3.30978 15.8685L4.0422 16.6009C4.51387 17.0726 5.14145 17.3333 5.80978 17.3333C6.47728 17.3333 7.10569 17.0734 7.57736 16.6009L9.41655 14.7617L10.0774 15.4225L11.2557 14.2441L3.75575 6.74413Z"
					fill="white"
				/>
			</svg>
		</div>
		<h2>
			{disconnectUser !== undefined ? disconnectUser : ""} has been disconnected, waiting for the host to spin again.
		</h2>
	</div>
{/if}

{#if $disconnectModalStore === true}
	<div class="disconnectModalWrapper">
		<DisconnectModal />
	</div>
{/if}
<!-- <div class="game-themes">
	<GameThemes />
</div> -->
<style>
	@import url("https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@300&display=swap");
	@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@600&display=swap");

	.marginBottom {
		margin-bottom: 40px;
	}

	.disconnectModalWrapper {
		position: fixed;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: end;
		top: -1rem;
	}

	#marginBottom {
		margin-bottom: 20px;
	}

	.circleProp {
		border-style: solid;
		border-color: white;
		border-width: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		width: 60vh;
		height: 60vh;
		max-width: 60vw;
		max-height: 60vw;
		border-radius: 50%;
	}

	.disconnectDiv {
		position: absolute;
		height: 47px;
		width: 310px;
		left: 1%;
		bottom: 10%;
		border-radius: 7px;
		background-color: #5e576a;
		border-radius: 7px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	.dissconectDivImg {
		/* Ellipse 83 */
		/* position: absolute; */
		display: flex;
		justify-content: center;
		align-items: center;
		width: 35px;
		height: 35px;
		left: 734px;
		top: 662px;
		border-radius: 100%;
		margin-right: 10px;
		background: #ac312f;
	}
	.disconnectDiv h2 {
		height: 32px;
		width: 227px;
		font-family: "Manrope", sans-serif;
		font-size: 12px;
		font-weight: 600;
		color: white;
	}

	#buttonDiv {
		margin-bottom: 20px;
	}
	#altModal {
		height: auto;
	}
	#altHeading {
		margin-top: 0;
		padding-top: 30px;
		padding-bottom: 0;
	}
	#question {
		margin-bottom: 20px;
	}
	.choice h5 {
		text-align: center;
		font-style: normal;
		font-weight: 800;
		margin: 0;
		width: 255px;
		font-size: 21px;
		line-height: 33px;
		font-family: "Manrope", sans-serif;
		color: #060d19;
		opacity: 0.84;
		margin-bottom: 20px;
	}
	.yellowSvg {
		color: linear-gradient(180deg, #fdbc06 0%, #fd990a 100%);
	}
	.peopleTruth {
		font-family: "Londrina Solid", cursive;
		width: 193px;
		height: 24px;
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		color: #5e96e8;
	}
	.peopleDare {
		font-family: "Londrina Solid", cursive;
		width: 193px;
		height: 24px;
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		color: #e96540;
	}
	.truthHeading {
		font-family: "Londrina Solid", cursive;
		position: relative;
		top: -14%;
		padding-bottom: 5px;
		padding-top: 20px;
		font-size: 30px;
		font-style: normal;
		font-weight: 700;
		line-height: 24px;
		letter-spacing: 0em;
		text-align: center;
		width: 100%;
		color: white;
		height: 60px;
		background: #5e96e8;
		border-radius: 20px 20px 0px 0px;
	}
	.dareHeading {
		font-family: "Londrina Solid", cursive;
		position: relative;
		top: -14%;
		padding-bottom: 5px;
		padding-top: 20px;
		font-size: 30px;
		font-style: normal;
		font-weight: 700;
		line-height: 24px;
		letter-spacing: 0em;
		text-align: center;
		width: 100%;
		color: white;
		height: 60px;
		background: #e96540;
		border-radius: 20px 20px 0px 0px;
	}
	.choice {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 85%;
		max-width: 320px;
		height: 208px;
		background: #ffffff;
		border: 8px solid #ffffff;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 20px;
	}
	.choice h1 {
		margin-top: 0;
		top: 15%;
		width: 180px;
		height: 24px;
		font-family: "Londrina Solid", cursive;
		font-style: normal;
		font-weight: bolder;
		font-size: 24px;
		line-height: 24px;
		text-align: center;
		color: #060d19;
	}
	.choice p {
		margin-top: 0;
		font-family: "Manrope", sans-serif;
		width: 255px;
		height: 19px;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 19px;
		text-align: center;
		color: #6c6c6c;
		opacity: 0.84;
	}
	.truth {
		font-family: "Londrina Solid", cursive;
		height: 60px;
		opacity: 94;
		width: 120px;
		font-size: 16px;
		font-weight: 700;
		color: white;
		border: none;
		border-radius: 41px;
		background: linear-gradient(180deg, #4a96f8 0%, #4686f7 100%);
	}

	.dare {
		font-family: "Londrina Solid", cursive;
		height: 60px;
		width: 120px;
		font-size: 16px;
		font-weight: 700;
		color: white;
		border: none;
		border-radius: 41px;
		background: linear-gradient(180deg, #e9693d 0%, #e8514d 100%);
	}
	.audience {
		font-family: "Londrina Solid", cursive;
		height: 60px;
		width: 120px;
		font-size: 16px;
		font-weight: 700;
		color: white;
		border: none;
		border-radius: 41px;
		background: linear-gradient(180deg, #feb507 0%, #fe9c0a 100%);
	}
	.bank {
		font-family: "Londrina Solid", cursive;
		height: 60px;
		width: 120px;
		font-size: 16px;
		font-weight: 700;
		color: white;
		border: none;
		border-radius: 41px;
		background: linear-gradient(180deg, #9208ff 0%, #5508b7 100%);
	}
	.forfeit {
		font-family: "Londrina Solid", cursive;
		height: 60px;
		width: 120px;
		font-size: 16px;
		font-weight: 700;
		color: white;
		border: none;
		border-radius: 41px;
		background: linear-gradient(180deg, #5d6570 0%, #1a1c20 100%);
	}
	.complete {
		font-family: "Londrina Solid", cursive;
		height: 60px;
		width: 120px;
		font-size: 16px;
		font-weight: 700;
		color: white;
		border: none;
		border-radius: 41px;
		background: linear-gradient(180deg, #07d68b 0%, #1ca573 100%);
	}

	.truth:hover {
		cursor: pointer;
		opacity: 100;
	}
	.dare:hover {
		cursor: pointer;
	}
	.bank:hover {
		cursor: pointer;
	}
	.audience:hover {
		cursor: pointer;
	}
	.forfeit:hover {
		cursor: pointer;
	}
	.complete:hover {
		cursor: pointer;
	}
	.ready:hover {
		cursor: pointer;
	}

	.modal {
		position: fixed;
		background-color: rgb(0, 0, 0);
		background-color: rgba(0, 0, 0, 0.4);
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 300;
	}
	#bottle {
		transition: all ease 7s;
		position: absolute;
		z-index: 100;
		width: 30vh;
		max-height: 30vw;
		max-width: 30vw;
		height: 30vh;
	}
	.waiting {
		height: 24px;
		line-height: 24px;
		color: white;
		font-family: "Londrina Solid", cursive;
	}

	.ready {
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 44px;
		margin: 10px;
		border-radius: 41px;
		font-family: "Londrina Solid", cursive;
		font-size: 18px;
		font-weight: bolder;
		color: #6c44a8;
		box-shadow: 0px 3px 10px 0px #6c44a880;
		padding: 0 30px;
	}

	.circleProp h2 {
		color: white;
		width: 30vh;
		max-width: 30vw;
		position: absolute;
		left: 0px;
		padding-left: 50%;
		text-align: center;
		font-family: "Londrina Solid", cursive;
		font-size: 19px;
	}

	.gameHeading {
		font-family: "Londrina Solid", cursive;
		color: white;

		font-size: 30px;
	}

	.game-themes {
		position: absolute;
		right: 50px;
		top: 13px;
		z-index: 1;
	}
</style>
